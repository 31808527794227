import { render, staticRenderFns } from "./FavoriteShows.vue?vue&type=template&id=b4634f0a&scoped=true&"
import script from "./FavoriteShows.vue?vue&type=script&lang=js&"
export * from "./FavoriteShows.vue?vue&type=script&lang=js&"
import style0 from "./FavoriteShows.vue?vue&type=style&index=0&id=b4634f0a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4634f0a",
  null
  
)

export default component.exports