import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=3184c8df&scoped=true&"
import script from "./Show.vue?vue&type=script&lang=js&"
export * from "./Show.vue?vue&type=script&lang=js&"
import style0 from "./Show.vue?vue&type=style&index=0&id=3184c8df&prod&lang=less&scoped=true&"
import style1 from "./Show.vue?vue&type=style&index=1&id=3184c8df&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3184c8df",
  null
  
)

export default component.exports